.research {
    background-color: white;
    color: #001B44;
    width: 90%;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 100px;
  
    .research-header {
      max-width: 1200px;
      margin: 0 auto 2rem;
      text-align: left;
  
      h1 {
        font-size: 3rem;
        font-weight: 700;
        background: linear-gradient(90deg, #0072CE, #005DAA); // adjust colors as needed
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
  
      p {
        font-size: 1.1rem;
        color: #333;
        margin-top: 1rem;
        max-width: 600px;
      }
    }
  
    .divider {
      border-bottom: 1px solid #ddd;
      margin: 2rem auto 3rem;
      max-width: 1200px;
    }

    .medium-link {
        display: inline-block;
        margin-top: 1rem;
        color: #0072CE;
        font-weight: 500;
        text-decoration: none;
        transition: color 0.2s ease;
      
        &:hover {
          text-decoration: underline;
          color: #005DAA;
        }
      }
  
    .research-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      gap: 2rem;
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 50px;
  
      .research-card {
        background-color: #f9f9f9;
        border-radius: 12px;
        text-decoration: none;
        overflow: hidden;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #ddd;
  
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 12px 30px rgba(0, 0, 0, 0.08);
        }
  
        .card-image img {
          width: 100%;
          height: 180px;
          object-fit: cover;
        }
  
        .card-text {
          padding: 1.2rem;
  
          h4 {
            font-size: 0.95rem;
            color: #666;
            margin-bottom: 0.3rem;
          }
  
          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: #001B44;
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            .arrow {
              font-size: 1rem;
            }
          }
  
          .authors {
            font-size: 0.85rem;
            color: #555;
            margin-top: 0.5rem;
          }
  
          .date {
            font-size: 0.8rem;
            color: #999;
            margin-top: 0.25rem;
          }
        }
      }
    }
  }

  .research-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 27, 68, 0.7); // Hoyalytics dark blue with transparency
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease-out;
    
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  
  .research-modal-content {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
    animation: slideUp 0.4s ease-out;
    
    @keyframes slideUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  .modal-close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 2rem;
    color: #0072CE;
    cursor: pointer;
    transition: color 0.2s ease;
    
    &:hover {
      color: #005DAA;
    }
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid #eee;
    
    .modal-image {
      width: 150px;
      height: 150px;
      object-fit: cover;
      border-radius: 12px;
      margin-right: 2rem;
    }
    
    .modal-title-section {
      h2 {
        font-size: 2rem;
        color: #0072CE;
        margin-bottom: 0.5rem;
      }
      
      .modal-date {
        color: #666;
        font-size: 1rem;
      }
    }
  }
  
  .modal-body {
    padding: 2rem;
    
    .modal-team-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      
      .team-section {
        flex: 1;
        
        h4 {
          color: #005DAA;
          margin-bottom: 0.5rem;
          font-size: 1.1rem;
        }
        
        p {
          color: #333;
        }
      }
    }
    
    .modal-abstract {
      h4 {
        color: #005DAA;
        margin-bottom: 1rem;
        font-size: 1.1rem;
      }
      
      p {
        color: #333;
        line-height: 1.6;
      }
    }
    
    .modal-actions {
      margin-top: 2rem;
      text-align: center;
      
      .presentation-link {
        display: inline-block;
        background-color: #0072CE;
        color: white;
        padding: 12px 24px;
        border-radius: 8px;
        text-decoration: none;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: #005DAA;
        }
      }
    }
  }
  
  // Responsive adjustments
  @media screen and (max-width: 768px) {
    .modal-header {
      flex-direction: column;
      text-align: center;
      
      .modal-image {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
    
    .modal-team-info {
      flex-direction: column;
      
      .team-section {
        margin-bottom: 1rem;
      }
    }
  }